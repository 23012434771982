import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Button from 'react-bootstrap/Button';

const Logout = () => {
  const { username, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/home');
  };

  return (
    <Button className="btn mb-2" variant="outline-light" onClick={handleLogout}>
      Logout <b>{username ? username : ''}</b>
    </Button>
    
  );
};

export default Logout;
