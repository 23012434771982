import React from "react";

function About() {

    return (
        <>
            <h1>About</h1>

        </>
    )
}

export default About;