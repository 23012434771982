import React, { useState, useContext } from 'react';
import { Button } from "react-bootstrap";
import './changePassword.css'
import { AuthContext } from '../context/AuthContext';

const ChangePassword = () => {
    const { username } = useContext(AuthContext);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('New passwords do not match!');
            return;
        }

        try {
            const response = await fetch('http://localhost:3333/api/auth/change-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, currentPassword, newPassword })
            });

            const data = await response.json();
            if (response.ok) {
                setMessage('Password changed succesfully!');
            } else {
                setMessage(`Error: ${data.message}`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    return (
        <div className='container-change-pass'>
            <div className='form-container'>
                <form className='form' onSubmit={handleSubmit}>
                    <input
                        placeholder='Current password'
                        className='inputkey w-95 p-2 my-3 fs-6'
                        type="password"
                        id="currentPassword"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                    <input
                        placeholder='New password'
                        className='inputkey w-95 p-2 my-3 fs-6'
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <input
                        placeholder='Confirm new password'
                        className='inputkey w-95 p-2 my-3 fs-6'
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <Button variant="success" type="submit">Change password</Button>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default ChangePassword;
