import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    const login = (username) => {
        setIsAuthenticated(true);
        setUsername(username);
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUsername('');
        localStorage.removeItem('token');
        navigate('/home');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                const username = decoded.username;
                setIsAuthenticated(true);
                setUsername(username)
                //navigate('/view');
            } catch (error) {
                console.error('Error decoding token:', error);
                localStorage.removeItem('token');
            }
        } else {
            //navigate('/home');
        }
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, username, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
