import React from "react";
import './License.css'
import Accordion from 'react-bootstrap/Accordion';

function License() {

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey="0" className="item">
                    <Accordion.Header>End User License Agreement</Accordion.Header>
                    <Accordion.Body>
                        <div class="document">
                            <h1>End User License Agreement (EULA)</h1>
                            <p>This End User License Agreement ("Agreement") is entered into between You ("User") and CodeStack ("Company").</p>
                            <p>Please read this Agreement carefully before using the Portal/Application. By registering, accessing, or using the Application, you agree to the terms of this Agreement. If you do not agree with the terms of this Agreement, do not register or use the Application.</p>

                            <h2>1. Definitions</h2>
                            <ul>
                                <li><strong>Application:</strong> An online service available at [URL address] that allows Users to register and store snippets of source code.</li>
                                <li><strong>User:</strong> An individual or legal entity that registers on the Application and uses its functionalities.</li>
                                <li><strong>Source Code:</strong> Snippets of programming code uploaded and stored by Users on the Application.</li>
                            </ul>

                            <h2>2. Registration and Account</h2>
                            <ul>
                                <li>To use the Application, the User must create an account by providing true and accurate registration information.</li>
                                <li>The User is responsible for maintaining the confidentiality of their password and account and for all activities that occur under their account.</li>
                                <li>The Company reserves the right to suspend or delete the User's account in case of violation of the terms of this Agreement.</li>
                            </ul>

                            <h2>3. User Rights and Obligations</h2>
                            <ul>
                                <li>The User represents that they own or have all necessary rights to the source code uploaded to the Portal/Application.</li>
                                <li>The User agrees not to upload source code that infringes copyrights, intellectual property rights, privacy rights, or other rights of third parties.</li>
                                <li>The User agrees not to use the Portal/Application in a manner that violates applicable laws or regulations.</li>
                            </ul>

                            <h2>4. Company Rights and Obligations</h2>
                            <ul>
                                <li>The Company agrees to take all reasonable measures to ensure the security and protection of User data.</li>
                                <li>The Company reserves the right to monitor and review source code uploaded by Users to ensure compliance with the terms of this Agreement.</li>
                                <li>The Company is not responsible for any loss or damage resulting from unauthorized access to the User's account.</li>
                            </ul>

                            <h2>5. Intellectual Property</h2>
                            <ul>
                                <li>All rights to the source code uploaded by Users remain with the Users.</li>
                                <li>The User grants the Company a non-exclusive, royalty-free, worldwide license to store, display, and share the source code as part of the operation of the Application.</li>
                            </ul>

                            <h2>6. Limitation of Liability</h2>
                            <ul>
                                <li>The Portal/Application is provided "as is" and "as available." The Company makes no warranties, express or implied, regarding the operation of the Application.</li>
                                <li>The Company is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from the use of the Application.</li>
                            </ul>

                            <h2>7. Final Provisions</h2>
                            <ul>
                                <li>This Agreement is governed by the laws of UE and will be interpreted in accordance with its provisions.</li>
                                <li>Any disputes arising from this Agreement will be resolved by the competent courts of UE.</li>
                                <li>The Company reserves the right to change the terms of this Agreement at any time. The amended terms will be posted on the Application, and continued use of the Application after the changes have been made constitutes acceptance of the new terms.</li>
                                <li>If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</li>
                            </ul>

                            <h2>Contact</h2>
                            <p>If you have any questions regarding this Agreement, please contact CodeStack at: andrew@codestack.io.</p>

                            <p><strong>Thank you for using CodeStack.</strong></p>
                        </div>
                    </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="item">
                        <Accordion.Header>Privacy Policy</Accordion.Header>
                        <Accordion.Body>
                            <div class="document">
                                <h1>Privacy Policy</h1>
                                <p>This Privacy Policy describes how [Company Name] ("Company," "we," "us," or "our") collects, uses, and discloses your information when you use our Portal/Application ("Service"). By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

                                <h2>1. Information We Collect</h2>
                                <ul>
                                    <li><strong>Personal Information:</strong> When you register for an account, we may collect personal information such as your name, email address, and other contact information.</li>
                                    <li><strong>Usage Data:</strong> We may collect information on how the Service is accessed and used, including your IP address, browser type, and device information.</li>
                                    <li><strong>Source Code:</strong> The source code snippets you upload to the Service.</li>
                                </ul>

                                <h2>2. How We Use Your Information</h2>
                                <ul>
                                    <li>To provide, maintain, and improve the Service.</li>
                                    <li>To manage your account and provide customer support.</li>
                                    <li>To monitor the usage of the Service and gather analytical data.</li>
                                    <li>To detect, prevent, and address technical issues.</li>
                                    <li>To send you updates, newsletters, and other information related to the Service, unless you opt-out of such communications.</li>
                                </ul>

                                <h2>3. Disclosure of Your Information</h2>
                                <ul>
                                    <li>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except as described in this Privacy Policy.</li>
                                    <li>We may share your information with service providers who assist us in operating the Service and conducting our business, provided they agree to keep your information confidential.</li>
                                    <li>We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                                    <li>We may disclose your information to protect our rights, property, or safety, and that of our users or the public.</li>
                                </ul>

                                <h2>4. Data Security</h2>
                                <p>We take reasonable measures to protect your information from unauthorized access, use, alteration, or destruction. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.</p>

                                <h2>5. Your Data Protection Rights</h2>
                                <ul>
                                    <li>You have the right to access, update, or delete the personal information we hold about you.</li>
                                    <li>You have the right to request that we restrict the processing of your personal information.</li>
                                    <li>You have the right to object to our processing of your personal information.</li>
                                    <li>You have the right to withdraw your consent at any time where we relied on your consent to process your personal information.</li>
                                </ul>
                                <p>To exercise any of these rights, please contact us at [Company Email Address].</p>

                                <h2>6. Changes to This Privacy Policy</h2>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective when they are posted on this page.</p>

                                <h2>7. Contact Us</h2>
                                <p>If you have any questions about this Privacy Policy, please contact us at [Company Email Address].</p>

                                <p><strong>Thank you for using our Service!</strong></p>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
            </Accordion>
        </>
    )
}

export default License;