import React, { useState } from "react";
import { Button, Modal, Row, Col, Container, Card } from "react-bootstrap";
import TypingEffect from 'react-typing-effect';
import Register from "./Register";
import "./Home.css";

const Home = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="homepage">
            <Container className="d-flex flex-column align-items-center">
                <Row className="mt-4 text-center">
                    <Col xs={12} md={12}>
                        <h1>Code Stock</h1>
                        <p>BETA</p>
                        <h2><TypingEffect
                            text={[
                                'type once, use many times',
                                'save, organize & share',
                                're-use',
                                'keep tested blocks of code'
                            ]}
                            speed={100}
                            eraseSpeed={50}
                            eraseDelay={2000}
                            typingDelay={1000}
                        /></h2>
                    </Col>
                </Row>
               
                <Row className="mt-4 text-center">
                    <Col xs={12} md={4}>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>Simple</Card.Title>
                                <Card.Text>
                                    No matter who you are, it's easy to use. Just create a free account, copy and paste your code, and save it for later use. This straightforward process ensures that anyone can quickly get started without hassle. Whether you're a beginner or an expert, the simplicity of the platform guarantees a smooth and efficient experience.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={4}>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>Free</Card.Title>
                                <Card.Text>
                                    Code Stock is free to use for everyone. There are no subscriptions, no ads, and no additional paid features. You can access all the functionalities without any hidden costs. Enjoy a seamless and unrestricted coding experience with Code Stack.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={4}>
                        <Card className="mb-2">
                            <Card.Body>
                                <Card.Title>Reliable</Card.Title>
                                <Card.Text>
                                    Code Stock is built to be dependable and trustworthy. With robust infrastructure and consistent performance, you can count on it to save and manage your code efficiently. Whether you're working on a simple project or a complex application, Code Stack ensures your code is secure and always accessible.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="ml-4 text-center register">
                    <Col xs={12} md={12}>
                        <Button disabled variant="dark" onClick={handleShow} className="mt-4">
                            Create free account (soon)
                        </Button>
                    </Col>
                </Row>

            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Register />
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Home;
