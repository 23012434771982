import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Row, Col} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AddElement from "./components/AddElement";
import ViewElement from "./components/ViewElement";
import Register from "./components/Register";
import Login from "./components/Login";
import Logout from './components/Logout';
import Sidebar from "./components/Sidebar";
import Home from './components/Home';
import UserSettings from './components/UserSettings';
import License from './components/License';
import About from './components/About'
import ForgotPassword from './components/ForgotPass';
import { AuthProvider, AuthContext } from './context/AuthContext';
import { RefreshProvider } from './components/RefreshContext';
import './App.css';

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? element : <Navigate to="/view" />;
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
      <RefreshProvider>
      <div className='App'>
        <Row className='margin gx-1'>
          <Col xs={12} md={12} lg={12} xl={3} xxl={3}>
              <Sidebar />
          </Col>
          <Col xs={12} md={12} lg={12} xl={9} xxl={9}>
            <div>
              <Routes>
                <Route path="/add" element={<PrivateRoute element={<AddElement />} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<Home />} />
                <Route path='/settings' element={<UserSettings />} />
                <Route path='/license' element={<License />} />
                <Route path='/about' element={<About />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} /> 
                <Route path="/view" element={<PrivateRoute element={<ViewElement />} />} />
              </Routes>
            </div>
          </Col>
        </Row>
        </div>
        </RefreshProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
