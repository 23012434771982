import React, { createContext, useState } from 'react';

export const RefreshContext = createContext();

export const RefreshProvider = ({ children }) => {
    const [refresh, setRefresh] = useState(false);

    const triggerRefresh = () => {
        setRefresh(prevRefresh => !prevRefresh);
    };

    return (
        <RefreshContext.Provider value={{ refresh, triggerRefresh }}>
            {children}
        </RefreshContext.Provider>
    );
};
