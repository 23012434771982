import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import "./Register.css"

const Register = () => {
    let currentDate = new Date();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [company, setCompany] = useState(Math.random() * (currentDate.getMilliseconds()));
    const [confirmEmail, setConfirmEmail] = useState('');
    const [plan, setPlan] = useState('free')

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        if (email !== confirmEmail) {
            alert("Emails do not match.");
            return;
        }
        
        try {
            const res = await axios.post('http://localhost:3333/api/auth/register', { username, email, password, company, plan });
            alert(res.data.message);
        } catch (err) {
            alert(err.response.data.message);
        }
    };

    return (
        <div className='regField'>
            <h2>Create new account</h2>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="User name" value={username} onChange={(e) => setUsername(e.target.value)} />
                <input type='email' placeholder='E-mail' value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type='email' placeholder='Confirm E-mail' value={confirmEmail} onChange={(e) => setConfirmEmail(e.target.value)} />
                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <input disabled type='text' placeholder='Company ID' value={company} onChange={(e) => setCompany(e.target.value)} />
                <input type='checkbox' id='acceptEULA' required/> <label for="acceptEULA">I accept <a className='acceptEULA' href="/license">EULA</a> and <a className='acceptEULA' href="/license">Privacy Policy</a></label>
                <Button type="submit" variant="dark">Register</Button>
            </form>
        </div>
    );
};

export default Register;
