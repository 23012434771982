import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';
import './Sidebar.css'
import Logout from './Logout';
import AddElement from './AddElement';
import Login from './Login';
import { Row, Col } from 'react-bootstrap';

const Sidebar = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <div className='sidebar'>
            <div className="sidebar-top-buttons">
                {isAuthenticated ? <Logout /> : <Login />}
            </div>
            <div className="sidebar-content">
                {isAuthenticated ? <AddElement /> : null}
            </div>
            <div className="sidebar-footer">
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                        <Col xs={12} md={12} lg={12} xl={12}>
                            {isAuthenticated ?
                                <NavLink to={"/view"}><b>My stacks</b></NavLink> : <NavLink to={"/"}><b>Home</b></NavLink>}
                        </Col>
                        <NavLink to={"/license"}>Documents</NavLink>
                        <NavLink to={"/about"}>About</NavLink>
                        {isAuthenticated ?
                            <NavLink to={"/settings"}>Settings</NavLink> : null}
                    </Col>
                    <Col>
                        <p className='copyright'>Created by human on Earth</p>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Sidebar;
