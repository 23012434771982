import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import './ForgotPass.css';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('http://localhost:3333/api/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setIsCodeSent(true);
                setMessage('Code sent! Please check your e-mail.');
            } else {
                setMessage('Oh no! Something went wrong. Please check e-mail or try again later.');
            }
        } catch (error) {
            setMessage('Error occurred. Please try again later.');
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            const response = await fetch('http://localhost:3333/api/auth/reset-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, username, code, newPassword }),
            });

            if (response.ok) {
                setMessage('Password successfully changed!');
            } else {
                setMessage('Invalid code, username, or something went wrong. Please try again.');
            }
        } catch (error) {
            setMessage('Error occurred. Please try again later.');
        }
    };

    return (
        <div className='container-reset-pass'>
            <h1>Reset your password</h1>
            <div className='form-container'>
                {!isCodeSent ? (
                    <form onSubmit={handleEmailSubmit} className='form'>
                        <input
                            className='inputkey w-95 p-2 my-3 fs-6'
                            type="email"
                            placeholder='Put your e-mail here'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <Button type="submit" variant='outline-success'>Proceed</Button>
                    </form>
                ) : (
                    <form onSubmit={handleCodeSubmit} className='form'>
                        <input
                            className='inputkey w-95 p-2 my-3 fs-6'
                            type="text"
                            placeholder='Enter your username'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <input
                            className='inputkey w-95 p-2 my-3 fs-6'
                            type="text"
                            placeholder='Enter the code you received'
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                        />
                        <input
                            className='inputkey w-95 p-2 my-3 fs-6'
                            type="password"
                            placeholder='Enter your new password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <input
                            className='inputkey w-95 p-2 my-3 fs-6'
                            type="password"
                            placeholder='Confirm your new password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                        <Button type="submit" variant='outline-success'>Change Password</Button>
                    </form>
                )}
            </div>
            <div>{message}</div>
        </div>
    );
};

export default ForgotPassword;
